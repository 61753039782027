import './layout.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { ThemeProvider } from 'atomize-jnh'
import { StaticQuery, graphql } from 'gatsby'
import 'intl'
import Nav from '../components/Nav'
import Footer from '../components/Footer'
import renderSocialBar from '../components/SocialSticky'

const theme = {
  grid: {
    containerMaxWidth: {
      xs: '540px',
      sm: '720px',
      md: '960px',
      lg: '1150px',
      xl: '1150px'
    },
    gutterWidth: '12px'
  },
  shadows: {
    front: '0 0px 6px 2px rgba(8, 11, 14, 0.1)'
  }
}

const Layout = ({ children, footer }) => {
  return (
    <StaticQuery
      query={graphql`
        query LayoutQuery {
          site {
            siteMetadata {
              title
              languages {
                defaultLangKey
                langs
              }
            }
          }
        }
      `}
      render={data => {
        return (
          <ThemeProvider theme={theme}>
            <div className="main">
              <Nav />
              <main>
                {renderSocialBar()}
                {children}
              </main>
              <Footer data={footer} />
            </div>
          </ThemeProvider>
        )
      }}
    />
  )
}

Layout.propTypes = {
  children: PropTypes.any,
  footer: PropTypes.object
}

export default Layout
