import React from 'react'
import PropTypes from 'prop-types'
import './index.css'
import { Button } from 'atomize-jnh'

function renderButton (props) {
  const { content, variant, onClick } = props

  const defaultButtonStyles = {
    h: { xs: '2.7rem', md: '37px' },
    p: { x: '1rem' },
    textSize: { xs: 'subheader', md: 'body' },
    w: '160px',
    m: { r: { md: '10.5px' } },
    rounded: 'circle',
    hoverShadow: '3',
    textWeight: '900'
  }

  function buttonStylesInterface () {
    switch (variant) {
      case 'primary':
        return {
          textColor: 'white',
          hoverTextColor: 'white',
          bg: '#4d68f0',
          hoverBg: '#1a3ded',
          borderColor: '#4d68f0',
          hoverBorderColor: 'info900',
          textWeight: { xs: '600' },
          border: '1px solid',
          m: { xs: '0 auto', md: '0 0' }
        }
      case 'secondary':
        return {
          textColor: '#4d68f0',
          bg: 'white',
          hoverBg: '#f7f7f7',
          textWeight: { xs: '600' },
          borderColor: '#4d68f0',
          border: '1px solid',
          m: { xs: '0 auto', md: '0 0' },
          d: { xs: 'none', md: 'flex' }
        }
      case 'light':
        return {
          textColor: '#5f77f1',
          bg: 'white',
          hoverBg: '#c5ccf0',
          borderColor: '#4d68f0',
          textWeight: { xs: '600' },
          hoverBorderColor: 'info900',
          m: { xs: '0 auto', md: '0 0' },
          h: { xs: '2.7rem', md: '37px' }
        }
      case 'buy':
        return {
          textColor: '#23baff',
          bg: '#e8f8ff',
          hoverBg: '#cff0ff',
          borderColor: '#4d68f0',
          hoverBorderColor: 'info900'
        }
      default:
        return {
          textColor: '#4d68f0',
          hoverTextColor: 'info900',
          bg: 'white',
          hoverBg: 'info200',
          borderColor: '#4d68f0',
          hoverBorderColor: 'info900'
        }
    }
  }

  return (
    <Button onClick={onClick} {...defaultButtonStyles} {...buttonStylesInterface()}>
      {content}
    </Button>
  )
}

renderButton.propTypes = {
  variant: PropTypes.string,
  content: PropTypes.string,
  onClick: PropTypes.func
}

export default renderButton
