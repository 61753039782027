/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'
import { useIntl } from 'gatsby-plugin-intl'

const staticCoinDescriptions = (intl, queryCoinId, defaultMsg) => {
  if (queryCoinId === 'BTC') {
    return intl.formatMessage({ id: 'marketSimple.BTC.metadescription' })
  } else if (queryCoinId === 'ETH') {
    return intl.formatMessage({ id: 'marketSimple.ETH.metadescription' })
  }
  return defaultMsg
}

function SEO ({ description, lang, meta, title, defaultImage, type, queryCoinId, keywords }) {
  useEffect(() => {
    const queryString = location.search
    const urlParams = new URLSearchParams(queryString)
    const ref = urlParams.get('ref')

    ref && localStorage.setItem('ref', ref)
  }, [])

  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
            googleSiteVerification {
              name
              content
            }
            criptosMetaData {
              default {
                title
                description
              }
              BTC {
                title
                description
              }
              ETH {
                title
                description
              }
              XRP {
                title
                description
              }
              XLM {
                title
                description
              }
              BNB {
                title
                description
              }
              DAI {
                title
                description
              }
              USDT {
                title
                description
              }
              LTC {
                title
                description
              }
              TRX {
                title
                description
              }
              CHA {
                title
                description
              }
              LUK {
                title
                description
              }
              BCH {
                title
                description
              }
              DASH {
                title
                description
              }
              EOS {
                title
                description
              }
              DOT {
                title
                description
              }
            }
          }
        }
      }
    `
  )
  const intl = useIntl()
  const metaDescription = description || intl.formatMessage({ id: 'metaDescription' })
  const finalDescription =
    type === 'coin-view'
      ? staticCoinDescriptions(
        intl,
        queryCoinId,
        site.siteMetadata.criptosMetaData[queryCoinId].description
      )
      : type === 'prices-view'
        ? intl.formatMessage({ id: 'marketSimple.metadescription' })
        : metaDescription
  return (
    <Helmet
      htmlAttributes={{ lang: intl.formatMessage({ id: 'langCode' }) }}
      title={
        type === 'coin-view'
          ? site.siteMetadata.criptosMetaData[queryCoinId].title
          : title || intl.formatMessage({ id: 'title' })
      }
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: 'keywords',
          content: keywords
        },
        {
          name: site.siteMetadata.googleSiteVerification.name,
          content: site.siteMetadata.googleSiteVerification.content
        },
        {
          name: 'description',
          content: finalDescription
        },
        {
          property: 'image',
          content: defaultImage
        },
        {
          property: 'og:title',
          content:
            type === 'coin-view' ? site.siteMetadata.criptosMetaData[queryCoinId].title : title
        },
        {
          property: 'og:description',
          content: finalDescription
        },
        {
          property: 'og:image',
          content: defaultImage
        },
        {
          property: 'og:url',
          content: site.siteMetadata.siteUrl
        },
        {
          property: 'og:type',
          content: 'website'
        },
        {
          name: 'twitter:card',
          content: 'summary'
        },
        {
          name: 'twitter:creator',
          content: site.siteMetadata.author
        },
        {
          name: 'twitter:title',
          content:
            type === 'coin-view' ? site.siteMetadata.criptosMetaData[queryCoinId].title : title
        },
        {
          property: 'twitter:image',
          content: defaultImage
        },
        {
          name: 'twitter:description',
          content: finalDescription
        }
      ].concat(meta)}
    />
  )
}

SEO.defaultProps = {
  lang: 'en',
  meta: [],
  description: ''
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  defaultImage: PropTypes.string,
  type: PropTypes.string,
  queryCoinId: PropTypes.string,
  keywords: PropTypes.array
}

export default SEO
