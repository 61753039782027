import React from 'react'
import PropTypes from 'prop-types'
import { Div, SideDrawer, Text, Icon } from 'atomize-jnh'
import handleRegister from '../../helpers/handleRegister'
import IniciarSesion from '../../assets/IniciarSesion.svg'
import CrearCuenta from '../../assets/CrearCuenta.svg'
import Monedas from '../../assets/Monedas.svg'
import Soporte from '../../assets/Soporte.svg'
import Exchange from '../../assets/Exchange.svg'
import Launchpad from '../../assets/Launchpad.svg'
import Seguridad from '../../assets/Seguridad.svg'

const navSpanStyles = {
  textColor: '#9290a5',
  m: '5px',
  textSize: 'caption',
  cursor: 'pointer'
}

const SideIcon = [Monedas, Soporte, Exchange, Launchpad, Seguridad]

const BasicSideDrawer = ({ isOpen, onClose, data }) => {
  BasicSideDrawer.propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    data: PropTypes.object
  }

  return (
    <>
      <SideDrawer isOpen={isOpen} onClose={onClose} w={{ xs: '16rem' }}>
        <CloseButton onClose={onClose} />
        <Div m={{ t: '2.5rem' }}>
          <Div m={{ t: '2.5rem' }}>
            <AuthButtons data={data} />
            <ItemsList data={data} />
          </Div>
        </Div>
      </SideDrawer>
    </>
  )
}

const AuthButtons = ({ data }) => {
  AuthButtons.propTypes = {
    data: PropTypes.object
  }

  return (
    <React.Fragment>
      <Div onClick={() => (window.location = 'https://app.orionx.com/login')} d="flex">
        <Div p={{ t: '10px', r: '7px' }}>
          <img src={IniciarSesion} />
        </Div>
        <Text
          tag="h3"
          p={{ t: '10px' }}
          style={{ fontSize: '1rem' }}
          textSize="title"
          {...navSpanStyles}>
          {data.buttons.signIn}
        </Text>
      </Div>
      <Div onClick={handleRegister} d="flex">
        <Div p={{ t: '10px', r: '7px' }}>
          <img src={CrearCuenta} />
        </Div>
        <Text
          tag="h3"
          p={{ t: '10px' }}
          style={{ fontSize: '1rem' }}
          textSize="title"
          {...navSpanStyles}>
          {data.buttons.signUp}
        </Text>
      </Div>
    </React.Fragment>
  )
}

const ItemsList = ({ data }) => {
  ItemsList.propTypes = {
    data: PropTypes.object
  }

  return data.spans.map((value, index) => {
    const SpanIcon = SideIcon[index]
    return (
      <Div key={index} onClick={() => (window.location = value.link)} d="flex">
        <Div p={{ t: '10px', r: '7px' }}>
          <img src={SpanIcon} />
        </Div>

        <Text p={{ t: '10px' }} tag="h3" style={{ fontSize: '1rem' }} {...navSpanStyles}>
          {value.span}
        </Text>
      </Div>
    )
  })
}

const CloseButton = ({ onClose }) => {
  CloseButton.propTypes = {
    onClose: PropTypes.func
  }

  return (
    <Div m={{ b: '0.7rem' }} d="flex" pos="relative">
      <Icon
        onClick={onClose}
        pos="absolute"
        right="0"
        h="20px"
        w="20px"
        name="Cross"
        color="#7c8084"
        size="20px"
      />
    </Div>
  )
}

export default BasicSideDrawer
