import React from 'react'
import { Row, Col, Div, Text, Icon, Container } from 'atomize-jnh'
import logo from '../../assets/logo.svg'
import { navigate } from 'gatsby'
import { FaDiscord } from 'react-icons/fa'
import './styles.css'
import { useIntl } from 'gatsby-plugin-intl'

const Footer = () => {
  const intl = useIntl()
  const footerData = {
    resources: {
      title: intl.formatMessage({ id: 'footer.resources.title' }),
      spans: [
        {
          span: intl.formatMessage({ id: 'footer.resources.support' }),
          link: 'https://support.orionx.com/es/',
          internal: false
        },
        {
          span: intl.formatMessage({ id: 'footer.resources.bounty' }),
          link: 'https://app.orionx.com/bounty-program',
          internal: false
        },
        { span: intl.formatMessage({ id: 'footer.resources.fees' }), link: '/fees', internal: true },
        {
          span: intl.formatMessage({ id: 'footer.resources.privacy' }),
          link: 'https://app.orionx.com/privacy-policy',
          internal: false
        },
        {
          span: intl.formatMessage({ id: 'footer.resources.security' }),
          link: '/security',
          internal: true
        }
      ]
    },
    account: {
      title: intl.formatMessage({ id: 'footer.account.title' }),
      spans: [
        {
          span: intl.formatMessage({ id: 'footer.account.accounts' }),
          link: 'https://app.orionx.com/accounts',
          internal: false
        },
        {
          span: intl.formatMessage({ id: 'footer.account.buySell' }),
          link: 'https://app.orionx.com/instant',
          internal: false
        },
        {
          span: intl.formatMessage({ id: 'footer.account.security' }),
          link: 'https://app.orionx.com/bounty-program',
          internal: false
        },
        {
          span: intl.formatMessage({ id: 'footer.account.verification' }),
          link: 'https://app.orionx.com/settings/kyc',
          internal: false
        }
      ]
    },
    socialMedia: {
      title: intl.formatMessage({ id: 'footer.socialMedia.title' })
    }
  }

  function spanClick (type, link) {
    if (type) {
      navigate(type)
    }
    window.location = link
  }

  function renderSpans (spans) {
    return spans.map((values, key) => {
      return (
        <Div key={key} onClick={() => spanClick(values.internal, values.link)} p={{ y: '5px' }}>
          <Text tag="a" textColor="#9290a5" m="5px" textSize="caption" cursor="pointer">
            {values.span}
          </Text>
        </Div>
      )
    })
  }

  return (
    <Container>
      <Row d="flex" p={{ t: '2rem', b: '4rem' }} justify="center">
        <Col order={{ xs: '4', md: '1' }} size={{ xs: '6', md: '3' }}>
          <Div
            bgImg={logo}
            bgRepeat="no-repeat"
            m={{ r: '5px', b: { xs: '.5rem', md: '0' }, t: { xs: '1rem', md: '1rem' } }}
            w="100%"
            h="33px"
            bgSize="contain"
          />

          <Div d={{ xs: 'flex', md: 'flex' }} p={{ t: { md: '0.6rem' } }}>
            <Text tag="a" textColor="#9290a5" m="5px" textSize="caption" cursor="pointer">
              contacto@orionx.com
            </Text>
          </Div>
        </Col>

        <Col order={{ xs: '2', md: '2' }} size={{ xs: '6', md: '3' }}>
          <Text m={{ t: { xs: '1rem' } }} tag="h3">
            {footerData.resources.title}
          </Text>
          <Div>{renderSpans(footerData.resources.spans)}</Div>
        </Col>
        <Col order={{ xs: '3', md: '3' }} size={{ xs: '6', md: '3' }}>
          <Text m={{ t: { xs: '1rem' } }} tag="h3">
            {footerData.account.title}
          </Text>
          <Div>{renderSpans(footerData.account.spans)}</Div>
        </Col>
        <Col order={{ xs: '3', md: '4' }} size={{ xs: '6', md: '3' }}>
          <Text m={{ t: { xs: '1rem' } }} tag="h3">
            {footerData.socialMedia.title}
          </Text>

          <Div d="flex" m={{ t: '1rem' }}>
            <Icon
              onClick={() => (window.location = 'https://www.facebook.com/orionx.crypto/')}
              hoverColor="info700"
              name="Facebook"
              color="#7c8084"
              size="20px"></Icon>
            <Icon
              onClick={() => (window.location = 'https://twitter.com/orionx')}
              hoverColor="info700"
              name="Twitter"
              color="#7c8084"
              size="20px"></Icon>
            <Icon
              onClick={() => (window.location = 'https://www.instagram.com/orionx.crypto/')}
              hoverColor="info700"
              name="Instagram"
              color="#7c8084"
              size="20px"></Icon>

            <FaDiscord
              onClick={() => (window.location = 'https://discord.gg/6SXtXWZ')}
              size="20"
              className="discord-icon"
            />

            <Icon
              onClick={() => (window.location = 'https://github.com/orionx-dev/')}
              hoverColor="info700"
              name="Github"
              color="#7c8084"
              size="20px"></Icon>
          </Div>
        </Col>
      </Row>
    </Container>
  )
}

export default Footer
