import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Icon } from 'atomize-jnh'
import BasicSideDrawer from '../../components/Drawers/BasicSideDrawer'

function Drawer (props) {
  const [isDrawerOpen, setDrawerState] = useState(false)
  
  return (
    <>
      <Button
        onClick={() => setDrawerState(true)}
        h="2.5rem"
        w="2.5rem"
        bg="white"
        textColor="white"
        hoverTextColor="white"
        borderColor="#4d68f0"
        hoverBorderColor="info900"
        hoverShadow="1"
        rounded="circle"
        shadow="3">
        <Icon name="Menu" size="20px" color="#34a1f8" />
      </Button>
      <BasicSideDrawer
        data={props.data}
        isOpen={isDrawerOpen}
        onClose={() => setDrawerState(false)}
      />
    </>
  )
}

Drawer.propTypes = {
  data: PropTypes.object
}

export default Drawer
