import BTC from './icons/BTC.svg'
import LTC from './icons/LTC.svg'
import ETH from './icons/ETH.svg'
import BCH from './icons/BCH.svg'
import DASH from './icons/DASH.svg'
import CLP from './icons/CLP.svg'
import MXN from './icons/MXN.svg'
import CO from './icons/CO.svg'
import AR from './icons/AR.svg'
import CHA from './icons/CHA.svg'
import IOTA from './icons/IOTA.svg'
import XRP from './icons/XRP.svg'
import LUK from './icons/LUK.svg'
// import XMR from './icons/XMR.svg'
import XLM from './icons/XLM.svg'
import DAI from './icons/DAI.svg'
import USDT from './icons/USDT.svg'
import TRX from './icons/TRX.svg'
import BNB from './icons/BNB.svg'
import EOS from './icons/EOS.svg'
import DOT from './icons/DOT.svg'

// https://github.com/lipis/flag-icon-css/tree/master/flags/1x1

export default [
  {
    currencyCode: 'BTC',
    icon: BTC,
    color: 'rgba(255,177,25,1)',
    secondaryColor: 'rgb(333, 206, 108)'
  },
  {
    currencyCode: 'LUK',
    icon: LUK,
    color: 'rgba(110, 210, 120, 1)',
    secondaryColor: 'rgb(111, 251, 152)'
  },
  {
    currencyCode: 'ETH',
    icon: ETH,
    color: 'rgb(111, 124, 186)',
    secondaryColor: 'rgb(142, 156, 222)'
  },
  {
    currencyCode: 'LTC',
    icon: LTC,
    color: 'rgb(152, 152, 152)',
    secondaryColor: 'rgb(206, 206, 206)'
  },
  {
    currencyCode: 'CHA',
    icon: CHA,
    full: true,
    color: '#36B4A5',
    secondaryColor: '#36B4A5'
  },
  {
    currencyCode: 'IOTA',
    icon: IOTA,
    color: '#111'
  },
  {
    currencyCode: 'XRP',
    icon: XRP,
    color: '#222',
    secondaryColor: '#4e4e4e'
  },
  {
    currencyCode: 'BCH',
    icon: BCH,
    color: '#8DC451',
    secondaryColor: 'rgb(111, 251, 152)'
  },
  {
    currencyCode: 'DASH',
    icon: DASH,
    color: '#0075c0',
    secondaryColor: 'rgb(59, 179, 255)'
  },
  // {
  //   currencyCode: 'XMR',
  //   icon: XMR,
  //   full: true,
  //   color: '#fff'
  // },
  {
    currencyCode: 'XLM',
    icon: XLM,
    color: '#08b5e5',
    secondaryColor: 'rgb(98, 221, 255)'
  },
  {
    currencyCode: 'BTCTN',
    icon: BTC,
    color: '#eee'
  },
  {
    currencyCode: 'CLP',
    countryCode: 'CL',
    icon: CLP,
    full: true,
    color: '#444'
  },
  {
    currencyCode: 'ARS',
    countryCode: 'AR',
    icon: AR,
    full: true,
    color: '#444'
  },
  {
    currencyCode: 'COP',
    countryCode: 'CO',
    icon: CO,
    full: true,
    color: '#444'
  },
  {
    currencyCode: 'MXN',
    countryCode: 'MX',
    icon: MXN,
    full: true,
    color: '#444'
  },
  {
    currencyCode: 'DAI',
    icon: DAI,
    full: true,
    color: '#F4B731'
  },
  {
    currencyCode: 'USDT',
    icon: USDT,
    full: true,
    color: '#F4B731'
  },
  {
    currencyCode: 'TRX',
    icon: TRX,
    full: true,
    color: '#dc062b'
  },
  {
    currencyCode: 'BNB',
    icon: BNB,
    full: true,
    color: '#FFFFFF'
  },
  {
    currencyCode: 'EOS',
    icon: EOS,
    full: true,
    color: '#000000'
  },
  {
    currencyCode: 'DOT',
    icon: DOT,
    full: true,
    color: '#E6007A'
  }
]
