import React from 'react'
import { Row, Col, Div, Text, Container } from 'atomize-jnh'
import handleRegister from '../../helpers/handleRegister'
import logo from '../../assets/logo.svg'
import Button from '../../components/Button'
import BasicSideDrawerButton from '../../containers/Drawers/BasicSideDrawerButton'
import { navigate } from 'gatsby'
import { useIntl } from 'gatsby-plugin-intl'
import './styles.css'

const Nav = props => {
  const intl = useIntl()
  const navSpanStyles = {
    textColor: '#9290a5',
    m: '5px',
    textSize: 'caption',
    cursor: 'pointer'
  }
  const data = {
    spans: [
      {
        span: intl.formatMessage({ id: 'nav.currency' }),
        link: '/criptomonedas-chile',
        internal: true
      },
      {
        span: intl.formatMessage({ id: 'nav.support' }),
        link: 'https://support.orionx.com/es/',
        internal: false
      },
      {
        span: intl.formatMessage({ id: 'nav.exchange' }),
        link: 'https://app.orionx.com/exchange',
        internal: false
      },
      {
        span: intl.formatMessage({ id: 'nav.launchpad' }),
        link: 'https://app.orionx.com/launchpad',
        internal: false
      },
      {
        span: intl.formatMessage({ id: 'nav.security' }),
        link: '/security',
        internal: true
      }
    ],
    buttons: {
      signIn: intl.formatMessage({ id: 'nav.signInButton' }),
      signUp: intl.formatMessage({ id: 'nav.signUpButton' })
    }
  }

  function handleClick (route) {
    if (route.internal) {
      navigate(`/${route.link}`)
    } else {
      window.location = route.link
    }
  }

  return (
    <Container h="65px" bg="white">
      <Row p={{ t: { md: '1rem', xs: '0.9rem' } }} d="flex">
        <Col d="flex" justify="flex-start" align="flex-end" size={{ xs: '10', md: '11', lg: '2' }}>
          <Div
            onClick={() => navigate('/')}
            bgImg={logo}
            bgRepeat="no-repeat"
            cursor="pointer"
            w={{ xs: '9.5rem' }}
            h={{ xs: '60px', md: '2.7rem' }}
            bgSize={{ xs: 'contain', md: 'contain' }}></Div>
        </Col>
        <Col d={{ lg: 'none', xs: 'block', md: 'flex' }} justify="center" size={{ xs: '2', md: '1' }}>
          <BasicSideDrawerButton data={data} />
        </Col>
        <Col d={{ lg: 'flex', xs: 'none' }} justify="flex-start" align="center" size="5">
          {data.spans.map((value, key) => {
            return (
              <Text key={key} onClick={() => handleClick(value)} tag="a" {...navSpanStyles}>
                {value.span}
              </Text>
            )
          })}
        </Col>
        <Col d={{ xs: 'none', lg: 'flex' }} justify="flex-end" size="5">
          <Div m={{ r: { md: '12px' } }}>
            <Button
              onClick={() => (window.location = 'https://app.orionx.com/login')}
              content={data.buttons.signIn}
              variant="secondary"
            />
          </Div>
          <Div pos="relative">
            <Button onClick={handleRegister} content={data.buttons.signUp} variant="primary" />
          </Div>
        </Col>
      </Row>
    </Container>
  )
}

export default Nav
