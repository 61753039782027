import React from 'react'
import './styles.css'
import { Icon, Div } from 'atomize'
import { FaDiscord } from 'react-icons/fa'
import { navigate } from 'gatsby'

function renderStickySocialBar () {
  return (
    <Div>
      <Div d={{ xs: 'none', md: 'flex' }}>
        <div className="icon-bar">
          <a onClick={() => navigate('/funnel?id=facebook')} className="facebook">
            <Icon name="Facebook" color="white" size="20px" />
          </a>
          <a onClick={() => navigate('/funnel?id=discord')} className="discord">
            <FaDiscord size="20" />
          </a>
          <a onClick={() => navigate('/funnel?id=instagram')} className="instagram">
            <Icon name="Instagram" color="white" size="20px" />
          </a>
          <a onClick={() => navigate('/funnel?id=twitter')} className="twitter">
            <Icon name="Twitter" color="white" size="20px" />
          </a>
          <a onClick={() => navigate('/funnel?id=github')} className="github">
            <Icon name="Github" color="white" size="20px" />
          </a>
        </div>
      </Div>
    </Div>
  )
}

export default renderStickySocialBar
