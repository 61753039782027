import React from 'react'
import './styles.css'
import PropTypes from 'prop-types'
import data from './data'

class CoinCircle extends React.Component {
  getData () {
    if (!this.props.countryCode) {
      return data.find(el => el.currencyCode === this.props.currencyCode) || data.BTC
    }
    return data.find(el => el.countryCode === this.props.countryCode)
  }

  getStyle () {
    if (!this.getData()) return {}
    return {
      height: this.props.size,
      width: this.props.size,
      display: 'inline-flex',
      ...(!this.getData().full && {
        background: `linear-gradient(0deg, ${this.getData().color} 0%, ${
          this.getData().secondaryColor
        } 100%)`
      }),
      ...(this.getData().full && { backgroundColor: this.getData().color }),
      ...(this.getData().full && {
        backgroundImage: `url(${this.getData().icon})`
      })
    }
  }

  getIconStyle () {
    const multiplier = 0.6
    return {
      height: this.props.size * multiplier,
      width: this.props.size * multiplier,
      backgroundImage: `url(${this.getData().icon})`
    }
  }

  renderIcon () {
    if (!this.getData()) return
    if (this.getData().full) return
    return <div className={'icon'} style={this.getIconStyle()} />
  }

  render () {
    return (
      <div className={'icon-container'} style={this.getStyle()}>
        {this.renderIcon()}
      </div>
    )
  }
}

export default CoinCircle

CoinCircle.propTypes = {
  currencyCode: PropTypes.string,
  size: PropTypes.number,
  countryCode: PropTypes.string
}
CoinCircle.defaultProps = { size: 40 }
